import styled from 'styled-components';

export const Container = styled.div`
  color: #001c72;
  position: relative;
  z-index: 100;
  .tub-selector-dropdown__single-value,
  .tub-selector-dropdown__option {
    display: flex;
  }

  .tub-selector-dropdown__control,
  .tub-selector-dropdown__option {
    cursor: pointer;
  }

  .tub-selector-dropdown__menu {
    z-index: 100;
  }

  ${({ styles }) => {
    switch (styles) {
      case 'no-styles':
        return `
        & .tub-selector-dropdown__control {
          background: unset;
          border: none;
          min-height: auto;
          font-family: 'Neuzeit Grotesk';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
        }
        & .tub-selector-dropdown__value-container {
          padding: 0;
        }
        & .tub-selector-dropdown__single-value span {
          font-family: 'Neuzeit Grotesk';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          text-transform: unset;
        }
        & .tub-selector-dropdown__indicator-separator {
          display: none;
        }
        & .tub-selector-dropdown__indicator {
          padding: 0;
        }
        & .tub-selector-dropdown__menu {
          min-width: 120%;
        }
        `;
      default:
        return ``;
    }
  }}
`;

export const IconWrapper = styled.div`
  width: 25px;
`;

export const Icon = styled.img`
  width: ${({ imageHeight }) => (imageHeight ? 'initial' : '100%')};
  height: ${({ imageHeight }) => imageHeight || 'initial'};
`;

export const ItemContainer = styled.div`
  display: block;
  position: relative;
  ${({ hasIcon }) =>
    hasIcon
      ? `
    margin-left: 30px;
    `
      : `margin-left: 5px`}
`;

export const Label = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  color: #001c72;
  letter-spacing: 2px;
  margin-right: 10px;
  &.no-styles {
    text-transform: unset;
    font-weight: normal;
  }
`;

export const SubTitle = styled.span`
  color: #001c72;
`;

export const Tag = styled.span`
  color: #fff;
  background-color: #c95865;
  border-radius: 4px;
  padding: 3px 5px;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: -2px;
  &.selected {
    top: -1px;
  }
`;
